<!--
 * @Author: lisushuang
 * @Date: 2021-11-17 13:58:50
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-20 13:53:52
 * @Description: 通用表单页面外部代理
 * @FilePath: /dataview-viewer-test/src/views/general/onlyForm.vue
-->
<template>
  <div v-loading="loading" style="width:100%;height:100%;min-height:200px">
    <FormOnlyOne
      v-if="showForm"
      :objectUuid="configForm.object_uuid"
      :viewUuid="configForm.view_uuid"
      :formUuid="configForm.form_uuid"
      :moreConditions="configForm.moreConditions"
      :moreCreateDicts="configForm.moreCreateDicts"
    />
  </div>
</template>

<script>
import FormOnlyOne from "@/custom-component/form/FormOnlyOne";

const configForm = {
  object_uuid:'object617f8e6b71ed8',
  view_uuid:'view617f8fafe2f8d',
  form_uuid:'',
  moreConditions:[],
  moreCreateDicts:[],
};

export default {
  name: "onlyForm",
  components: { FormOnlyOne },
  data(){
    return {
      configForm:configForm,
      loading:true,
      showForm:false
    }
  },
  mounted(){
    // 请求页面配置数据，找到configform
    // getPageData()
    this.loading = false
    this.showForm = true
  }
};
</script>

<style scoped lang="less">
</style>
